<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div style="background-color:#fff;padding: 10px;">
        <top-filter
          :config="{'policytype':true}"
          :hasSearch="false"
          @handleSearch="getList2ByFilter"
          @handleSearchParams="getList2ByFilter"
        ></top-filter>
      </div>
      <div style="background-color:#fff;padding: 10px;margin-top: 20px;">
        <div
          v-for="item in dataList2"
          :key="item.id"
          class="item-wrapper"
          @click="articleClick(item)"
        >
          <div class="title-wrapper">
            <div class="mianyang">绵阳</div>
            <div class="title">{{ item.title }}</div>
          </div>
          <div v-html="item.content" class="content ql-editor"></div>
          <div class="time-footer h4">
            <span style="margin-right:100px;">四川省科学技术厅</span>
            <span>{{ item.time?item.time.substring(0,10):'--' }}</span>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total2}} 条</div>

          <el-pagination
            background
            layout="prev, pager, next"
            :total="total2"
            :current-page.sync="queryParams2.pageNum"
            :page-size="queryParams2.pageSize"
            @current-change="getList2"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
import topFilter from '@/components/topFilter.vue'

export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
    topFilter
  },
  data() {
    return {
      dataList2: [],
      queryParams2: {
        pageNum: 1,
        pageSize: 6,
        keywords: null,
      },
      // 总条数
      total2: 0,
    }
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const keywords = this.$route.query?.keywords
    if (keywords) {
      this.queryParams2.keywords = keywords
      this.getList2()
    }
  },
  mounted() {
  },
  methods: {
    getList2ByFilter(v) {
      this.queryParams2['areaType'] = v?.policytype
      this.getList2()
    },
    getList2() {
      const params = {
        ...this.queryParams2,
      }
      this.$api.policyHall.infoList(params).then((res) => {
        const data = res.data
        if (data) {
          this.dataList2 = data.rows;
          this.total2 = data.total;
        }
      })
    },
    articleClick(item) {
      var path = '/policyHall/page-guojia/articledetail'
      if (item.areaType == '2') {
        path = '/policyHall/page-sheng/articledetail'
      }
      else if (item.areaType == '3') {
        path = '/policyHall/page-shi/articledetail'
      }
      this.$router.push({ path: path, query: { id: item.id, 'detailType': 'policyHall-policy' } })
    },
  }
}
  </script>
  <style scoped lang="less">
.item-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: grey dotted 2px;
  padding: 20px 20px;
  cursor: pointer;
  .title-wrapper {
    display: flex;
    align-content: center;
    .mianyang {
      line-height: 30px;
      padding: 0 20px;
      background-color: #0031bd;
      margin-right: 20px;
      color: #fff;
    }
    .title {
      color: #3333cc;
    }
  }
  .content {
    max-height: 100px;
    overflow: hidden;
  }
  .time-footer {
    margin: 10px 0;
  }
}
</style>
  